/* tslint:disable */ 
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Input, Button } from "@mui/material";
import Jobcard from "./components/Jobcard";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import LoadingSpinner from "./components/Loadingspinner";
import Loadmorebutton from "./components/Loadmorebutton";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { useInfiniteLoading } from "./utils/useInfiniteLoading";
import { useInfiniteLoadingSearch } from "./utils/useInfiniteLoadingSearch";
import { UserContext } from "./context/UserContext";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
import Footer from "./components/Footer";

// Initialize Google Analytics
ReactGA.initialize('G-PG1ZYL20C9'); // replace with your Tracking ID

function App(props) {
  let location = useLocation();

  const client = props.client;
  //get users data from API
  const darkTheme = createTheme({
    typography: {
      fontFamily: 'Cyber',
    },
    palette: {
      mode: "dark",
      primary: {
        light:"#65e7a2",
        main: "#65e7a2",
        dark: "#65e7a2",
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light:"#65e7a2",
        main: "#65e7a2",
        dark: "#65e7a2",
      },
    },
  });

  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;

  const navigate = useNavigate();
  const [userContext, setUserContext] = useContext(UserContext) as [
    { token: string | null },
    React.Dispatch<React.SetStateAction<{ token: string | null }>>
  ];
  const [shnavigate, setShouldnavigate] = useState("profile");
  const [start_search, setStart] = useState(0);
  const [signinstate, setSignin] = useState(false);
  const [registerstate, setRegister] = useState(false);
  const [searchquery, setSearchquery] = useState<string | undefined>(undefined);
  const [isAuthenticated, setisAuth] = useState(false);
  const [jobsearch, setjobsearch] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { jobsi, hasMore, loadjobs } = useInfiniteLoading(setIsLoading);
  const {
    jobsisearch,
    hasMores,
    loadjobsearchinit,
    loadjobsearchmore,
  } = useInfiniteLoadingSearch(setIsLoading);

  const scrollPositionRef = useRef(0);

  //Handle Authentication of user
  const verifyUser = useCallback(() => {
    fetch(url + "refreshToken", {
      method: "POST",
    //  mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async (response) => {
      // console.log(response);
      if (response.ok) {
        // console.log("verify user");
        const data = await response.json();
        // console.log("data.token", data.token);
        window.localStorage.setItem("token", data.token);
        setUserContext((oldValues) => {
          return { ...oldValues, token: data.token };
        });
      } else {
        // console.log("else verify user");
        window.localStorage.setItem("token", null);
        setUserContext((oldValues) => {
          return { ...oldValues, token: null };
        });
      }
      // call refreshToken every 10 minutes to renew the authentication token.
      setTimeout(verifyUser, 10 * 60 * 1000);
    });
  }, [setUserContext]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  useEffect(() => {
    console.log(location.pathname, location.search);
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Custom Title" });
    console.log("reactga", ReactGA)
  }, [location]);

  // Restore scroll position after loading is finished
  useEffect(() => {
    if (!isLoading) {
      window.scrollTo(0, scrollPositionRef.current);
    }
  }, [isLoading]);

  //Search in main page
  if (location.state == "restartsearch") {
    setStart(0);
    location.state = "not";
  }

  function loadmore(event: React.MouseEvent<HTMLButtonElement>, search_query?: string) {
    event.preventDefault();

    // Save the current scroll position
    scrollPositionRef.current = window.scrollY;

    if (searchquery) {
      loadjobsearchmore(searchquery);
    } else {
      loadjobs(0);
    }
  }

  //Backend url
  function customClickEvent(e: any) {
    // console.log("custom event", e)
    let slug = e.name.replace(/[^a-zA-Z0-9]/g, '-').replace(/-+/g, '-').toLowerCase();
    navigate(`/jobs/${e.id}/${slug}`, { state: "partialjob" });
  }

  function navpostajob(){
    if(userContext.token){//connected
      navigate("/postajob")}else{
      // show sign in page
      // console.log("setting");
      setSignin(true);
      setShouldnavigate("postajob");
     }
  }

  const handleSearch = (event: any) => {
    setIsLoading(true);
    if (event.target.value === "") {
      setStart(0);
      setSearchquery(undefined);
      setIsLoading(false);
    } else {
      setStart(1);
      const search_query = event.target.value;
      setSearchquery(search_query);
      loadjobsearchinit(search_query);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <Helmet>
          <title>Eksodos - Find Jobs that Pay In Crypto</title>
          <link rel="canonical" href="https://eksodos.com/" />

          <meta name="description" content="Eksodos - The leading platform for jobs that pay in cryptocurrency. Search and apply for crypto jobs across all industries." />
          <meta name="keywords" content="Jobs, Cryptocurrency, Bitcoin, Ethereum, Crypto Jobs, Blockchain, Remote Jobs, Tech Jobs, Programming Jobs, Eksodos" />
          <meta property="og:title" content="Eksodos - Find Jobs that Pay In Crypto" />
          <meta property="og:description" content="Eksodos - The leading platform for jobs that pay in cryptocurrency. Search and apply for crypto jobs across all industries." />
          <meta property="og:image" content="URL_TO_IMAGE" />
          <meta property="og:url" content="https://eksodos.com" />

          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <header className="App-header">
          <ResponsiveAppBar nav={shnavigate} signinstate={signinstate} registerstate={registerstate} client={client}/>
          <nav style={{ display: 'none' }}>
            <a href="/">Home</a>
            <a href="/jobs">Jobs</a>
            <a href="/postajob">Post a Job</a>
            <a href="/profile">Profile</a>
          </nav>
        </header>
        <main>
          <br></br>
          {console.log("userContext token", userContext.token)}
          <div style={{ display: "inline-block" }}>
            <Button
              sx={{ "border-radius": "0px", "fontFamily":"Cyber", "border":"2px", "padding":"10px 20px 10px 20px", margin: "20px" }}
              variant="outlined"
              onClick={navpostajob  }
            >
              Post a Job
            </Button>
            <br></br>
            {userContext.token ? (
              <Button  sx={{ "border-radius": "0px" }}  variant="outlined" onClick={() => navigate("/profile")}>
                Your Posted Jobs
              </Button>
            ) : null}
          </div>
          <br></br>
          <Input
            sx={{ margin: "20px", width : "25%" }}
            id="search"
            type="search"
            placeholder="Search for jobs"
            onChange={handleSearch}
          />
          {isLoading ? <LoadingSpinner /> : (
            <div className="joblistcards">
              <ul className="ullistjobs">
                {start_search > 0
                  ? jobsisearch.map(function (jobs: any) {
                      return (
                        <Jobcard
                          sx={{ "border-radius": "0px" }}
                          customClickEvent={customClickEvent}
                          job={jobs}
                        ></Jobcard>
                      );
                    })
                  : jobsi.map(function (jobs: any) {
                      return (
                        <Jobcard
                          sx={{ "border-radius": "0px" }}
                          customClickEvent={customClickEvent}
                          job={jobs}
                        ></Jobcard>
                      );
                    })}
              </ul>
              {start_search > 0 && hasMores ? (
                <button id="loadmorebutton"
                  onClick={(e) => loadmore(e, searchquery)}
                >Load more</button>
              ) : null}
              {start_search <= 0 && hasMore ? (
                <button id="loadmorebutton"
                  onClick={(e) => loadmore(e, searchquery)}
                >Load more</button> 
              ) : null}
            </div>
          )}
          <Outlet />
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Blog.css';

interface Article {
  id: number;
  title: string;
  content: string;
  image?: string;
}

export default function ArticlePage() {
  const [article, setArticle] = useState<Article | null>(null);
  const [relatedArticles, setRelatedArticles] = useState<Article[]>([]);
  const { articleId } = useParams();
  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;

  useEffect(() => {
    // Fetch main article
    axios.get(`${url}articles/${articleId}`)
      .then(response => {
        setArticle(response.data);
        // After getting the main article, fetch related articles
        return axios.get(`${url}articles`);
      })
      .then(response => {
        // Filter out the current article and get up to 3 related articles
        const otherArticles = response.data
          .filter((a: Article) => a.id !== Number(articleId))
          .slice(0, 3);
        setRelatedArticles(otherArticles);
      })
      .catch(error => {
        console.error('Error fetching article:', error);
      });
  }, [articleId]);

  if (!article) {
    return (
      <div className="article-loading">Loading...</div>
    );
  }

  const article_summary = article.content.split(" ").length > 60 
    ? article.content.split(" ").slice(0, 60).join(" ") 
    : article.content;

  // Function to generate a slug from a title
  const generateSlug = (title: string) => title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');

  return (
    <>
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article_summary} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article_summary} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        {article.image && <meta property="og:image" content={article.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article_summary} />
        {article.image && <meta name="twitter:image" content={article.image} />}
      </Helmet>
      
      <div className="article-page">
        <div className="article-nav">
          <div className="home-icon-container">
            <Link to="/blog" className="home-icon-link">
              <img src="/icons8-exit-64.png" alt="Back to Blog" className="home-icon" />
            </Link>
          </div>
          <button className="loadmorebutton">
            <Link to="/" className="findlink">Find Jobs</Link>
          </button>
        </div>
        
        <div className="article-container">
          <h1 className="article-title">{article.title}</h1>
          <div className="article-content" dangerouslySetInnerHTML={{ __html: article.content }}></div>
          
          {relatedArticles.length > 0 && (
            <div className="related-articles">
              <h2>Related Articles</h2>
              <div className="related-articles-grid">
                {relatedArticles.map((relatedArticle) => (
                  <Link 
                    key={relatedArticle.id}
                    to={`/articles/${relatedArticle.id}/${generateSlug(relatedArticle.title)}`}
                    className="related-article-card"
                  >
                    <h3>{relatedArticle.title}</h3>
                    <p>{relatedArticle.content.slice(0, 100)}...</p>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
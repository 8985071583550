import { Link, Outlet } from "react-router-dom";
import { useState, useEffect, useCallback, useContext } from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { UserContext } from "../context/UserContext"
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const url = process.env.REACT_APP_SERVER_API_ENDPOINT;

export default function ResetPassword(props) {
  const [userContext, setUserContext] = useContext(UserContext);
  const [password, setPassword] = useState("")
  const navigate = useNavigate();

  let { token } = useParams();

  interface formDataType {[key:string]: FormDataEntryValue}

  const responseBody: formDataType = {}

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        responseBody.password = password
        console.log(responseBody)
        
        {/* let data = responseBody; */}
        //
        {/* console.log(url + 'reset-password/'+ token  , { data }); */}
        axios.patch(url + 'reset-password/'+ token  , responseBody)
        .then(res => {
        
        // go to main menu page : routing 
        if(res.statusText == 'OK'){

        //if ok popup password correctly reset and navigate to main 
          navigate("/");
        }else{
          alert("Something went wrong!!");
          }
      })
      }

  const inputChangeHandler = (setFunction: React.Dispatch<React.SetStateAction<string>>, event: React.ChangeEvent<HTMLInputElement>) => {
        setFunction(event.target.value)
    }


  return  (
    <div className="reset-password-container">
      <ResponsiveAppBar client={props.client}/>
      <div className="reset-form-wrapper">
        <h1>Reset Your Password</h1>
        <form onSubmit={onSubmitHandler} className="reset-form">
          <div className="form-group">
            <label htmlFor="password">Enter your new password:</label>
            <input 
              id="password" 
              onChange={(e)=>inputChangeHandler(setPassword, e)} 
              type="password"
              className="password-input"
              placeholder="Enter a strong password"
              required
            />
          </div>
          <button type="submit" className="submit-button">Reset Password</button>
        </form>
        <div className="navigation-links">
          <Link to="/" className="nav-link">Back to Home</Link>
          <Link to="/blog" className="nav-link">Read Our Blog</Link>
        </div>
      </div>
      <style>{`
        .reset-password-container {
          min-height: 100vh;
          background: rgb(28,28,28);
        }
        
        .reset-form-wrapper {
          max-width: 500px;
          margin: 60px auto;
          padding: 40px;
          background: rgba(38,38,38,0.9);
          border: 2px solid rgb(144,144,144);
        }
        
        .reset-form-wrapper h1 {
          color: var(--red);
          margin-bottom: 30px;
          text-align: center;
          font-family: "Cyberpunk", sans-serif;
        }
        
        .form-group {
          margin-bottom: 20px;
        }
        
        .form-group label {
          display: block;
          margin-bottom: 10px;
          color: #fff;
        }
        
        .password-input {
          width: 100%;
          padding: 12px;
          background: rgb(28,28,28);
          border: 2px solid rgb(144,144,144);
          color: #fff;
          font-size: 16px;
        }
        
        .submit-button {
          width: 100%;
          padding: 12px;
          background: var(--red);
          color: #fff;
          border: none;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease;
        }
        
        .submit-button:hover {
          background: var(--blue);
        }
        
        .navigation-links {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          gap: 20px;
        }
        
        .nav-link {
          color: #fff;
          text-decoration: none;
          font-size: 14px;
          transition: color 0.3s ease;
        }
        
        .nav-link:hover {
          color: var(--blue);
        }
      `}</style>
    </div>
  );
}
